@import '../../../styles/colors.scss';

.multipleTagsIndicator{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 2px;
    transition: all 0.3s ease-in-out;
    .multipleTagsIndicatorCircle{
        width: 10px;
        height: 10px;
        border-radius: 100px;
        transition: all 0.3s ease-in-out;
        @include theme(border, secondaryBackground, 1.5px solid);
        &--blue{
            @include theme(background-color, tagBlue);
        }
    
        &--gray{
            @include theme(background-color, tagGray);
        }
    
        &--red{
            @include theme(background-color, tagRed);
        }
    
        &--yellow{
            @include theme(background-color, tagYellow);
        }
    
        &--green{
            @include theme(background-color, tagGreen);
        }
    
        &--pink{
            @include theme(background-color, tagPink);
        }
    
        &--purple{
            @include theme(background-color, tagPurple);
        }
    
        &--cian{
            @include theme(background-color, tagCian);
        }
    
        &--orange{
            @include theme(background-color, tagOrange);
        }
    }

    
}
