@import '../styles/colors.scss';

.BaseButton {
    border: none;
    border-radius: 6px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
  
    font-size: 14px;
    height: 44px;
  
    cursor: pointer;
  
    padding-left: 50px;
    padding-right: 50px;
  
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  
    &.Primary {
      color: white;
      // background-image: linear-gradient(to right, #72bbff, #576aff, #beb2ff);
      // background-color: red;
      @include theme(background-color, primaryBackground);
      transition: 0.3s;
      &:hover {
        background-image: linear-gradient(to right, #6baeec, #4e60eb, #a89de7);
      }
      &:active {
        background-image: linear-gradient(to right, #5a9be6, #3d4de5, #8a8ae1);
      } 
  
      &.Primary:disabled{
        background-image: linear-gradient(to right, #72bbff78, #576bff78, #beb2ff61);
        cursor: not-allowed;
      }
    }
  
    &.Special {
      color: white;
      background-image: linear-gradient(to right, #727bff, #5795ff, #feb2ff);
      transition: 0.3s;
      &:hover {
        background-image: linear-gradient(to right, #5a64fb, #3d83fb, #f99bfa);
      }
      &:active {
        background-image: linear-gradient(to right, #4854ff, #337cf9, #f562f7);
      } 
    }
  
    &.Secondary {
      color: #2a2a2a;
      background-color: #a5a4a4;
      transition: 0.3s;
      &:hover {
        background-color: #a5a4a4;
      }
      &:active {
        background-color: #8a8a8a;
      }
    }
  
    &.Tertiary {
      color: white;
      background-color: #3d59d4;
      transition: 0.3s;
      &:hover {
        background-color: #3d59d4;
      }
      &:active {
        background-color: #3d59d4;
      }
    }
  
    &.Default {
      border-radius: 6px;
    }
  
    &.Rounded {
      border-radius: 100px;
    }
  
    &.Large {
      height: 58px;
    }
  
    &.ExtraLarge {
      font-size: 30px;
      height: 78px;
    }
  
    &.Medium {
      height: 44px;
    }
  
    &.Small {
      font-size: 10px;
      height: 28px;
    }
  
    &.AutoSize{
      padding-left: 15px;
      padding-right: 15px;
    }
  
    &.Full {
      width: 100%;
    }
    &.Auto {
      width: auto;
    }
}
  