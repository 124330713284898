@import '../../../styles/colors.scss';

.ToastNotificationContainer{
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    @include theme(background-color, primaryBackground);
    border-radius: 4px;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    padding: 10px 15px;   

    @include theme(border, generalStroke, 1px solid);

    //transition: all 0.3s;
}

// enter from bottom right
@keyframes customerEnter {
    0% {
        transform: translate(100%, 0);
        opacity: 0;
    }
    100% {
      transform: translate(0, 0);
      opacity: 1;
    }
}
  
@keyframes customLeave {
    0% {
        transform: translate(0, 0);
        opacity: 1;
    }
    100% {
        transform: translate(100%, 0);
        opacity: 0;
    }
}

@keyframes fadeOut {
    from {opacity: 1;}
    to {opacity: 0;}
}

.animateEnter {
    animation: customerEnter 0.2s ease-in-out;
}
  
.animateLeave {
    animation: fadeOut 0.2s forwards;
}

