@import '../../../styles/colors.scss';

.ExpandButtonContainer{
    cursor: pointer;
    width: 24px;
    height: 24px;

    .ExpandButtonIcon{
        transition: transform 0.2s ease-in-out;
        @include theme(stroke, primaryText);

        &--Down{
            transform: rotate(0deg);
        }

        &--Right{
            transform: rotate(-90deg);
        }
    }
}
