@import '../../../styles/colors.scss';

.TagRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    //width: 100%;

    padding: 8px;

    transition: all 0.2s ease-in-out;
    border-radius: 8px;

    background-color: transparent;
    cursor: pointer;

    .TagRowActions{
        opacity: 0;
    }

    &:hover{
        @include theme(background-color, hover);
        .TagRowActions{
            opacity: 1;
        }
    }
}
