@import '../../../styles/colors.scss';

.Button {
    border: none;
    border-radius: 6px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'Poppins', sans-serif;
    font-weight: bold;

    cursor: pointer;
    transition: 0.3s;
    gap: 8px;

    &.auto {
        width: auto;
    }

    &.fit-content {
        width: fit-content;
    }

    &.full {
        width: 100%;
    }

    &:disabled{
        cursor: not-allowed;
    }

    &.contained {
        &.primary{
            @include theme(background-color, tabdockBlue);
            @include theme(color, white);
            &:hover{
                @include theme(background-color, tabdockDarkBlue);
            }

            &:active{
                @include theme(background-color, tabdockDarkBlue);
            }

            &.loading{
                @include theme(background-color, tabdockDarkBlue);
                @include theme(color, white);
            }
        }
        &.secondary{
            @include theme(background-color, white);
            @include theme(color, secondaryText);
        }
        &.tertiary{
            @include theme(background-color, tertiaryText);
            @include theme(color, primaryText);
        }
        &.black{
            @include theme(background-color, darkButton);
            @include theme(color, inverseText);
        }
        &.success{
            @include theme(background-color, success);
            @include theme(color, white);
        }
        &.error{
            @include theme(background-color, red);
            @include theme(color, white);
        }

        &:disabled{
            @include theme(background-color, tertiaryText);
            @include theme(color, primaryText);
            &:hover{
                @include theme(background-color, tertiaryText);
                @include theme(color, primaryText);
            }

            &:active{
                @include theme(background-color, tertiaryText);
                @include theme(color, primaryText);
            }
        }
    }

    &.outlined {
        background-color: transparent;
        @include theme(border, generalStroke, 1px solid);
        @include theme(color, primaryText);

        &.primary{
            @include theme(border, tabdockBlue, 1px solid);
            @include theme(color, tabdockBlue);

            &:hover{
                @include theme(background-color, tabdockBlue);
                @include theme(color, white);
            }
        }

        &.secondary{
            @include theme(border, secondaryText, 1px solid);
            @include theme(color, secondaryText);

            &:hover{
                @include theme(background-color, secondaryText);
                @include theme(color, white);
            }
        }

        &.success{
            @include theme(border, success, 1px solid);
            @include theme(color, success);

            &:hover{
                @include theme(background-color, success);
                @include theme(color, white);
            }
        }

        &.error{
            @include theme(border, red, 1px solid);
            @include theme(color, red);

            &:hover{
                @include theme(background-color, red);
                @include theme(color, white);
            }
        }

        &.white-outline{
            @include theme(border, white, 1px solid);
            @include theme(color, white);

            &:hover{
                @include theme(background-color, white);
                @include theme(color, tabdockBlue);
            }
        }
    }

    &.text {
        background-color: transparent;
        //@include theme(border, secondaryText, 1px solid);
        border: 1px solid transparent;

        &.primary{
            @include theme(color, tabdockBlue);
        }
        &.secondary{
            @include theme(color, secondaryText);

            &:hover{
                @include theme(color, white);
                @include theme(background-color, secondaryText);
                //@include theme(border, tertiaryText, 1px solid);
            }
        }
        &.tertiary{
            @include theme(color, tertiaryText);
        }
        &.black{
            @include theme(color, inverseText);
        }
        &.success{
            @include theme(color, success);
        }
    }

    &.small {
        font-size: 12px;
        padding: 4px 8px;
    }

    &.medium {
        font-size: 14px;
        padding: 8px 16px;
    }

    &.large {
        font-size: 16px;
        padding: 12px 24px;

        &.icon {
            padding: 3px 16px;
        }
    }
}
