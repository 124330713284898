@import '../../../styles/colors.scss';


.theme-switch{

  cursor: pointer;

  border-radius: 4px;
  @include theme(background, hover);

  padding: 8px 12px;


  .theme-switch-container {
    cursor: pointer;
    input {
      display: none;
      + div {
        border-radius: 50%;
        width: 24px;
        height: 24px;
        position: relative;
        box-shadow: inset 9px -9px 0 0 var(--color-toggle-dark, #606165);
        transform: scale(1) rotate(-2deg);
        transition: box-shadow 0.5s ease 0s, transform 0.4s ease 0.1s;
  
        &::before {
          content: "";
          width: inherit;
          height: inherit;
          border-radius: inherit;
          position: absolute;
          left: 0;
          top: 0;
          transition: background 0.3s ease;
        }
  
        &::after {
          content: "";
          width: 4px;
          height: 4px;
          border-radius: 50%;
          margin: -2px 0 0 -2px;
          position: absolute;
          top: 50%;
          left: 50%;
          box-shadow: 0 -16px 0 var(--color-toggle-light, #E1E3E6), 0 16px 0 var(--color-toggle-light, #E1E3E6), 16px 0 0 var(--color-toggle-light, #E1E3E6), -16px 0 0 var(--color-toggle-light, #E1E3E6), 11px 11px 0 var(--color-toggle-light, #E1E3E6), -11px 11px 0 var(--color-toggle-light, #E1E3E6), 11px -11px 0 var(--color-toggle-light, #E1E3E6), -11px -11px 0 var(--color-toggle-light, #E1E3E6);
          transform: scale(0);
          transition: all 0.3s ease;
        }
      }
  
      &:checked + div {
        box-shadow: inset 32px -32px 0 0 #fff;
        transform: scale(0.5) rotate(0deg);
        transition: transform 0.3s ease 0.1s, box-shadow 0.2s ease 0s;
  
        &::before {
          background: var(--color-toggle-light, #eee);
          transition: background 0.3s ease 0.1s;
        }
  
        &::after {
          transform: scale(1.5);
          transition: transform 0.5s ease 0.15s;
        }
      }
    }
  }
  
}

