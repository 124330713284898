@import '../../../styles/colors.scss';

.MessageCardContainer{
    border-radius: 4px;
    padding: 16px;

    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    @include theme(background-color, primaryBackground);
    @include theme(border, strokeBackgroundHover, 1.5px solid);

    word-wrap: break-word;
    
    font-family: "Poppins", sans-serif;
    display:flex;
    flex-direction: column;
    gap: 8px;

    position: fixed;
    z-index: 100;

    &--Title{
        font-size: 14px;
        font-weight: 500;
        @include theme(color, primaryText);
        padding: 0;
        margin: 0;
        //text-overflow:clip;
        //white-space: nowrap;
    }

    &--Message{
        font-size: 12px;
        font-weight: 400;
        @include theme(color, secondaryText);
        padding: 0;
        margin: 0;
    }
}
