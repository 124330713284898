@import '../../../styles/colors.scss';

.WorkspacesList{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    &--Header{
        padding: 42px 0px 20px 50px;

        display: flex;
        flex-direction: row;
        align-items: center;

        gap: 12px;

        &--Icon{
            margin-top: 12px;
        }

        .Button{
            margin-right: 10px;
        }
    }

    &--ActionBar{
        padding: 0px;
        width: 100%;
    }

    &--List{
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        display:flex;
        flex-direction: column;

        box-sizing: border-box;
        
        margin-top: 30px;
        margin-bottom: 10px;

        //padding: 0px 55px 0px 55px;
        padding: 0px 3% 0px 3%;
    }

    &--NoWorkspaces{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        height: 100%;
    }
}
