@import '../../../styles/colors.scss';

.TabdockHalfScreenContainer{
    width: 100%;
    height: 100%;

    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    &--Blue{
        @include theme(background-color, tabdockBlue);
        width: 100%;
        height: 100%;

        position: relative;

        &--Image {
            width: 100%;
            height: 100%;
            background: url("../../../assets/pattern.png"), 50% / auto no-repeat;
            mix-blend-mode: multiply;
        }

        &--Icon{
            position: absolute;
            top: 10px;
            left: 20px;
        }
    }

    &--Content{
        //@include theme(background-color, strokeBackground);
        @include theme(background-color, primaryBackground);
        width: 100%;
        height: 100%;
    }
}
