@import '../../../styles/colors.scss';

.IconButton{
    cursor: pointer;
    border-radius: 30%;
    padding: 2px;

    transition: all 0.2s ease-in-out;

    box-sizing: content-box;
    display: flex;
    justify-content: center;
    align-items: center;

    .success{
        @include theme(stroke, success);
    }

    .success-fill{
        @include theme(fill, success);
    }

    .primary{
        @include theme(stroke, primaryText);
    }

    .primary-fill{
        @include theme(fill, primaryText);
        @include theme(color, primaryText);
    }

    .extension-fill{
        @include theme(fill, white);
    }

    .secondary{
        @include theme(stroke, secondaryText);
    }

    @include theme(color, secondaryText);

    .secondary-fill{
        @include theme(fill, secondaryText);
        @include theme(color, secondaryText);
    }

    .tertiary{
        @include theme(stroke, tertiaryText);
    }

    .tertiary-fill{
        @include theme(fill, tertiaryText);
    }

    .active{
        @include theme(stroke, actionColor);
    }

    .active-fill{
        @include theme(fill, actionColor);
    }

    &:hover{
        @include theme(background-color, actionColorBackground);
    }

    &:active{
        @include theme(background-color, actionColorBackgroundClick);
    }



    &.disabled{
        cursor: not-allowed;
        opacity: 0.5;
    }
}
