@import '../../../styles/colors.scss';

.ModalComponentContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include theme(background-color, primaryBackground);
    border-radius: 4px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    z-index: 3000;

    &--Header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @include theme(border-bottom, strokeBackground, 2px solid);
        padding: 16px;

        &--Close{
            @include theme(stroke, secondaryText);
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            &:hover{
                @include theme(stroke, primaryText);
            }
        }
    }

    &--Content{
        height: 100%;
        padding: 16px;
    }

    &--Footer{
        padding: 16px;
        @include theme(border-top, strokeBackground, 2px solid);

        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }
}
