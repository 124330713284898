@import '../../../styles/colors.scss';

.ExpandSideBarButtonContainer{
    cursor: pointer;
    width: 24px;
    height: 24px;

    border-radius: 7px;
    @include theme(border, strokeBackground, 2px solid);
    @include theme(background, primaryBackground);

    transition: all 0.3s ease-in-out;

    .ExpandSideBarButtonIcon{
        transition: transform 0.2s ease-in-out;
        @include theme(stroke, secondaryText);
        transition: all 0.3s ease-in-out;

        &:hover{
            @include theme(stroke, primaryText);
        }

        &--Left{
            transform: rotate(0deg);
        }

        &--Right{
            transform: rotate(-180deg);
        }
    }
}

.ExpandSideBarButtonContainerRounded{
    border-radius: 50%;
}
