
.RequestFeatureScreen{
    display: flex;
    flex-direction: column;
    
    padding: 0px 0px 0px 0px;

    

    margin-bottom: 30px;
    overflow-y: auto;
    box-sizing: border-box;/* added */

    width: 100vw;
    height: 100vh;
    overflow: hidden; /* Prevents any overflow issues */

    &--Header{
        padding: 42px 0px 20px 50px;
    }

    &--HeaderLine{
        margin-top: -8px;
    }
}
/*
[data-canny] {
    width: 100%;
    height: 100%;
}
    */

