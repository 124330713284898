@import '../../../styles/colors.scss';

.CommandRowContainer{
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: transparent;

    padding: 0;
    width: 100%;
    transition: all 0.2s ease-in-out;


    &--Row{
        width: 100%;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        padding: 8px;

        transition: all 0.2s ease-in-out;
        border-radius: 8px;

        background-color: transparent;
        
        position: relative;
        &::after{
            content: "";
            position: absolute;
            right: 50%;
            top: -30px;
            bottom: -10px;
            width: 2px; 
            @include theme(background-color, strokeBackground);
            
        }

        &--Left{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: 50%;
            transition: all 0.2s ease-in-out;

            margin-left: 30px;
        }

        &--Right{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: 49%;

            transition: all 0.2s ease-in-out;

            position: relative;

            &--Actions{
                //display: flex;
                //flex-direction: row;
                //justify-content: flex-end;
                //align-items: center;  
                
                opacity: 0;
                transition: all 0.2s ease-in-out;

                position: absolute;
                right: 30px;
            }
        }

        

        &--Divider{
            border:none;
            @include theme(background-color, strokeBackground);
            //height:50px;
            width:2px;  

            padding: 0;
            margin: 0;

        }

    }

    &:hover{
        .CommandRowContainer--Row--Right--Actions{
            opacity: 1;
        }
    }

    &--Divider {
        line-height: 2px;
        position: relative;
        outline: 0;
        border: 0;
        text-align: center;
        height: 2px;

        width: 100%;

        transition: all 0.3s ease-in-out;
        @include theme(background, strokeBackground);
    }
}
