@import '../../../styles/colors.scss';

.CircularProgressbarDisplay{
    display: flex;
    flex-direction: column;
    gap: 10px;

    justify-content: center;
    align-items: center;
}

.CircularProgressbarDisplayBackground{
    background-color: red;
    padding: 5px;
    border-radius: 10px;

    @include theme(background-color, strokeBackground);
    @include theme(border, generalStroke, 1px solid);
    transition: all 0.2s ease-in-out;

}
