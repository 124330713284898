@import '../../../styles/colors.scss';

.InstallationStepsSidebarContainer{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    width: 400px;
    height: 100%;

    justify-content: space-between;

    @include theme(border-right, strokeBackgroundHover, 2px solid);
    @include theme(background-color, primaryBackground);

    transition: all 0.3s ease-in-out;

    box-sizing: border-box;

    &--header{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &--steps{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0px;
    }

    &--footer{
        display: flex;
        flex-direction: column;
        gap: 5px;


    }
}

.active-line{
    width: 3px;
    height: 3vh;
    margin-left: 24px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 10px;

    @include theme(background-color, tabdockBlue);
}

.completed-line{
    width: 3px;
    height: 3vh;
    margin-left: 24px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 10px;

    @include theme(background-color, success);
}

.pending-line{
    width: 3px;
    height: 3vh;
    margin-left: 24px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 10px;

    @include theme(background-color, secondaryText);
}
