@import '../../../styles/colors.scss';

.SaveBarContainer{
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 12px;

	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    @include theme(background-color, primaryBackground80);
    backdrop-filter: blur(5px);

	padding: 4px;
	padding-left: 20px;
	padding-right: 20px;

	border-radius: 46px;
}

.SavingIcon{
    animation: rotate-animation 3s infinite linear;
}

@keyframes rotate-animation {
	0% {
		transform: rotate(0deg);
  }
  50% {
		transform: rotate(-180deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}
