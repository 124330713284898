
    .LearnTabDockSliderContainer{
        position: relative;
        width: 80%;
    
        
        .image-legend {
            position: absolute;
            bottom: 10%;
            width: 45%;

            right: 0;
            
            background-color: white;

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            padding: 25px;
            padding-right: 70px;

            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

            border-radius: 5px 0 0 5px;

            gap: 10px;
        }
    }

    

    

