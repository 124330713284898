@import '../../../styles/colors.scss';

.OpenTabRow{
    //@include theme(background-color, primaryBackground80);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    gap: 5px;

    //width: 100%;

    padding: 8px;
    transition: all 0.2s ease-in-out;
    border-radius: 8px;
    background-color: transparent;

    cursor:grab;

    &--checkbox{
        width: 16px;
        height: 16px;

        border-radius: 4px;
        border: 1px solid #9EA2AA;
        background: #FFF;

        cursor: pointer;
    }

    &:hover{
        @include theme(background-color, hover);
    }
}

.OpenTabRowDragging{
    //box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: transparent;
    opacity: 0.5;
}

.OpenTabRowCustomPointer{
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0px 2px 5px rgba(0,0,0,0.3);
    @include theme(background, primaryBackground);
    @include theme(border, generalStroke, 1px solid);
}
