@import '../../../styles/colors.scss';

.GoogleCalendarSettings{
    display: flex;
    flex-direction: column;
    width: 100%;
    
    padding: 50px 30px 0px 30px;

    height: 100%;

    margin-bottom: 30px;
    overflow-y: auto;
    box-sizing: border-box;

    gap: 25px;
    &::-webkit-scrollbar {
        background-color: transparent;
        width: 8px;
    }
    
    &:hover{
        &::-webkit-scrollbar {
            width: 8px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: rgba(0,0,0,.5);
            border-radius: 20px;
        }
    }

    &--Header{
        //padding: 42px 0px 20px 50px;
    }

    &--HeaderLine{
        margin-top: 15px;
    }

    &--Content{
        display: flex;
        flex-direction: column;
        gap: 20px;
        //padding: 0px 50px 0px 50px;
    }
}
