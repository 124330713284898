@import '../../../styles/colors.scss';

.ListHeaderContainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: relative;

    &--Title-edit{
        border: 1.5px solid transparent;
        cursor: pointer;
        &:hover{
            @include theme(border , tertiaryText, dashed 1.5px);

            .ListHeaderContainer--Title-edit--icon{
                @include theme(color , primaryText);
            }
        }

        &--icon{
            color:transparent;
        }
    }

    &--right{

    }

    &--ActiveCircle{
        width: 10px;
        height: 10px;

        @include theme(background, success);

        border-radius: 10px;
    }

    &--Popover{
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
    }
}
