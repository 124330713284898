@import '../../../styles/colors.scss';

.TagListContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    gap: 8px;
}
