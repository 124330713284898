.carousel{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    transition: transform 0.5s;

    position: relative;

    gap:10px;
    
    .carousel-slides{
        
        .carousel__item{
            flex: 0 0 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            display: none;
            position: relative;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;

                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            }
        }

        .active-slide{
            display: flex;
        }
    }
    
    .carousel__button{

    }
    .carousel__indicator{
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 10px;
        width: 100%;
        .carousel__indicator__item{
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: white;
            margin: 0 5px;
            cursor: pointer;
            transition: background-color 0.5s;
            &:hover{
                background-color: rgba(255, 255, 255, 0.5);
            }
        }
    }
    .carousel__indicator__item--active{
        background-color: rgba(255, 255, 255, 0.5);
    }
}
