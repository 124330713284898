.ImageBackground{
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;

    position: fixed;
    top:0;
    left:0;
    z-index: -2;

    overflow: hidden;
}

.ImageBackground-Author{
    position: absolute;
    bottom:5px;
    left:20px;
}


.SavingIconImgBG{
    animation: rotate-animation-ImgBG 3s infinite linear;
}

@keyframes rotate-animation-ImgBG {
	0% {
		transform: rotate(0deg);
  }
  50% {
		transform: rotate(-180deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}
