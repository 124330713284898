@import '../../../styles/colors.scss';

.TooltipIconContainer{
    position: relative;
    &--Message{
        @include theme(background-color, primaryBackground);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

        visibility: hidden;
        padding: 10px;
        border-radius: 4px;

        position: absolute;
        z-index: 1;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        
        width: max-content;
        max-width: 250px;
    }

    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &--Icon-primary{
        @include theme(stroke, secondaryText);
        cursor: pointer;
    }
    &--Icon-white{
        @include theme(stroke, white);
        cursor: pointer;
    }
}
