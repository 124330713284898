
.widgetContainer {
    display: none;
}
  
  /* Display only the .widgetHome */
.widgetContainer .widgetHome {
    display: block;
    width: 100%;
    height: 100%;
}
