@import '../../../styles/colors.scss';

.BackgroundLine {
    line-height: 2px;
    position: relative;
    outline: 0;
    border: 0;
    text-align: center;
    height: 2px;

    width: 100%;

    transition: all 0.3s ease-in-out;
    @include theme(background, strokeBackground);
  }
