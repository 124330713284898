@import '../../../styles/colors.scss';

.IntegrationRowMainContainer{
    width: 100%;
    display: flex;
    flex-direction: column;

    gap: 20px;
    margin-bottom: 5px;
    .IntegrationRowContainer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    
        &--Image{
            border-radius: 86.5px;
            width: 40px;
            height: 40px;
        }
    
        &--Actions{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
    
            gap: 20px;
    
            &--Button{
                @include theme(border-left, strokeBackground, 2px solid);
                padding-left: 20px;
            }

            &--LearnMore{
                cursor: pointer;
                width: 200px;
            }

            .ExpandButtonIconIntegration{
                transition: transform 0.2s ease-in-out;
                @include theme(stroke, primaryText);

                &--Up{
                    transform: rotate(180deg);
                }
                
                &--Down{
                    transform: rotate(0deg);
                }
            }
        }
    }

    .IntegrationRowDetails{
        margin-left: 60px;
    }
}

