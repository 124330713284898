
.SignInFormContainer{
    position: relative;

    height: 100%;
    width: 400px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 25px;

    &--Link{
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    &--Bottom{
        //position: absolute;
        //bottom: 10px;
        margin-top: 5px;
    }
}
