@import '../../../styles/colors.scss';

.GoogleCalendarEventContainer{
    height: 100%;
    border-radius: 8px;

    box-sizing: border-box;
    padding: 10px;

    display: flex;
    flex-direction:column;
    justify-content: space-between;

    &--Header{
        display: flex;
        flex-direction: column;
        gap: 3px;
    }
}

.calendar-shadow{
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.calendar-all-day{
    display: flex;
    flex-direction:row;
    justify-content: space-between;

    align-items: center;
}

.calendar-color-violet{
    @include theme(background-color, caledarViolet);
    @include theme(border-left, caledarVioletDark, 3px solid);
}

.calendar-color-green{
    @include theme(background-color, calendarGreen);
    @include theme(border-left, calendarGreenDark, 3px solid);
}

.calendar-color-purple{
    @include theme(background-color, calendarPurple);
    @include theme(border-left, calendarPurpleDark, 3px solid);
}

.calendar-color-pink{
    @include theme(background-color, calendarPink);
    @include theme(border-left, calendarPinkDark, 3px solid);
}

.calendar-color-yellow{
    @include theme(background-color, calendarYellow);
    @include theme(border-left, calendarYellowDark, 3px solid);
}

.calendar-color-orange{
    @include theme(background-color, calendarOrange);
    @include theme(border-left, calendarOrangeDark, 3px solid);
}

.calendar-color-blue{
    @include theme(background-color, calendarBlue);
    @include theme(border-left, calendarBlueDark, 3px solid);
}

.calendar-color-gray{
    @include theme(background-color, calendarGray);
    @include theme(border-left, calendarGrayDark, 3px solid);
}

.calendar-color-oceanBlue{
    @include theme(background-color, calendarOceanBlue);
    @include theme(border-left, calendarOceanBlueDark, 3px solid);
}

.calendar-color-grass{
    @include theme(background-color, calendarGrass);
    @include theme(border-left, calendarGrassDark, 3px solid);
}

.calendar-color-red{
    @include theme(background-color, calendarRed);
    @include theme(border-left, calendarRedDark, 3px solid);
}
