@import '../../../styles/colors.scss';

.tabgroup{
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-weight: 500;

    border-radius: 6px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 6px;

    padding: 6px 9px;
    color: #2F2F2F;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &--circle{
        border-radius: 6px;
        padding: 6px;

        height: 16px;
        width: 16px;
    }

    &--blue{
        @include theme(background-color, tabGroupBlue);
    }

    &--grey{
        @include theme(background-color, tabGroupGray);
    }

    &--red{
        @include theme(background-color, tabGroupRed);
    }

    &--yellow{
        @include theme(background-color, tabGroupYellow);
    }

    &--green{
        @include theme(background-color, tabGroupGreen);
    }

    &--pink{
        @include theme(background-color, tabGroupPink);
    }

    &--purple{
        @include theme(background-color, tabGroupPurple);
    }

    &--cyan{
        @include theme(background-color, tabGroupCian);
    }

    &--orange{
        @include theme(background-color, tabGroupOrange);
    }
}
