@import '../../../styles/colors.scss';

.InstallationProcessContainer{
    height: 100vh;
    width: 100vw;

    display: flex;

    &--sidebar{
        height: 100%;
    }

    &--content{
        @include theme(background-color, strokeBackground);
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        //justify-content: space-between;
        align-items: center;

        overflow: auto;

        &--header{
            display: flex;
            flex-direction: column;
            gap: 20px;

            margin-top: 30px;

            width: 100%;

            align-items: center;
            justify-content: center;
        }

        &--content{
            display: flex;
            flex-direction: column;
            gap: 0px;

            align-items: center;
            justify-content: center;

            overflow-y:auto;

            img{
                margin-top: 50px;
            }
        }

        &--footer{
            position: fixed; /* Fix the footer to the bottom */
            bottom: 8px; /* Align the footer to the bottom of the viewport */
            width: calc(100% - 550px);
            margin-left: 0px;
            
            box-sizing: border-box;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            padding: 20px; 
        }
    }
    
}

.InstallationProcessCornerRightUp{
    position: absolute;
    top: 50px;
    right: 30px;
    z-index: 1000;
}

