@import '../../../styles/colors.scss';

.TextFieldBoxContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    &.full{
        width: 100%;
    }

    &.auto{
        width: auto;
    }

    &.fit-content{
        width: fit-content;
    }

    .TextFieldBox{
        height: 44px;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;

        width: 100%;
        //@include theme(border, generalStroke, 1px solid);
        @include theme(border, inputStroke, 1px solid);
        border-radius: 6px;
        padding: 0 10px;

        float: left;
        -ms-box-sizing: border-box; /* ie8 */
        -khtml-box-sizing: border-box; /* konqueror */
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box; /* Firefox, other Gecko */
        box-sizing: border-box; /* Opera/IE 8+ */
        box-sizing: border-box; /* css3 rec */

        transition: all 0.3s ease-in-out;

        @include theme(background-color, primaryBackground);
        @include theme(color, primaryText);
    
        &:focus {
            @include theme(outline, tabdockBlue, 2px solid); 
        }

        &::placeholder {
            @include theme(color, secondaryText); 
        }

        &.TextFieldBoxError{
            @include theme(border-color, red);
        }

        &.TextFieldBoxDisabled{
            @include theme(background-color, strokeBackground);
            @include theme(color, tertiaryText);
            cursor: not-allowed;
        }

        &.full{
            width: 100%;
        }

        &.auto{
            width: auto;
        }

        &.fit-content{
            width: fit-content;
        }
    }

    .TextFieldEyeIcon{
        cursor: pointer;
        @include theme(color, generalStroke);
    }
}
