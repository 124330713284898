
.WorkspacesScreenContainer{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 0px 0px 0px 0px;
    overflow: hidden;

    &--Right{
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        &--Header{
            padding: 42px 0px 0px 10px;
    
            display: flex;
            flex-direction: row;
            align-items: center;
    
            gap: 12px;

            margin-right: 10px;

            position: relative;
            &--Menu{
                position: absolute;
                right: 5px;
                top:100px;

                z-index: 100;
            }
        }

        &--Line{
            margin-top: 12px;
        }

        &--List{
            width: 100%;
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            display:flex;
            flex-direction: column;

            box-sizing: border-box;/* added */
            
            margin-top: 30px;
            margin-bottom: 10px;
    
            padding: 0px 0px 0px 0px;
        }
    }
}
