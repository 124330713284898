@import '../../../styles/colors.scss';

.Text{
    font-family: "Poppins", sans-serif;
    display: "inline-block";
    margin: 0;
    padding: 0;

    transition: all 0.2s ease-in-out;

    a{  
        color: inherit;
        transition: all 0.2s ease-in-out;
        
        &:hover{
            @include theme(color, tabdockBlue);
            transition: all 0.2s ease-in-out;

        }
    }

    &.cut{
        white-space: nowrap; 
        overflow: hidden; 
        text-overflow: ellipsis; 
    }

    &.align-center{
        text-align: center;
    }

    &.primary{
        @include theme(color, primaryText);
    }

    &.secondary{
        @include theme(color, secondaryText);
    }

    &.tertiary{
        @include theme(color, tertiaryText);
    }

    &.error{
        @include theme(color, red);
    }

    &.success{
        @include theme(color, green);
    }

    &.white{
        color: #F3F4F8;
    }

    &.black{
        color: #2F2F2F;
    }
    
    &.light{
        font-weight: 400;
    }

    &.regular{
        font-weight: 500;
    }

    &.semibold{
        font-weight: 600;
    }

    &.bold{
        font-weight: 700;
    }

    &.clock{
        font-size: 96px;
        opacity: 0.88;
        mix-blend-mode: hard-light;
    }

    &.super-clock{
        font-size: 145px;
        opacity: 0.88;
        mix-blend-mode: hard-light;
    }

    &.large{
        font-size: 48px;
    }

    &.title{
        font-size: 40px;
    }

    &.subtitle{
        font-size: 20px;
    }

    &.regular{
        font-size: 16px;
    }

    &.small{
        font-size: 14px;
    }

    &.tiny{
        font-size: 12px;
    }

    &.micro{
        font-size: 10px;
    }

    &.line-through{
        text-decoration: line-through;
    }

    &.italic{
        font-style: italic;
    }

    &.elevated{
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    &.uppercase{
        text-transform: uppercase;
    }

    &.capitalize{
        text-transform: capitalize;
    }

    &.lowercase{
        text-transform: lowercase;
    }

    &.text-center{
        text-align: center;
    }

    &.text-left{
        text-align: left;
    }

    &.text-right{
        text-align: right;
    }

    &.text-justify{
        text-align: justify;
    }

    &.text-nowrap{
        white-space: nowrap;
    }

    &.text-break{
        word-break: break-word;
    }

    &.text-truncate{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
