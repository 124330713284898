@import '../../../styles/colors.scss';

.tag{
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;

    border-radius: 4px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 6px;

    padding: 2px 6px;

    .tagCircle{
        width: 8px;
        height: 8px;
        border-radius: 50%;
    }

    &--blue{
        @include theme(background-color, tagBlueBackground);
        @include theme(color, tagBlue);
        .tagCircle{
            @include theme(background-color, tagBlue);
        }
    }

    &--grey{
        @include theme(background-color, tagGrayBackground);
        @include theme(color, tagGray);
        .tagCircle{
            @include theme(background-color, tagGray);
        }
    }

    &--red{
        @include theme(background-color, tagRedBackground);
        @include theme(color, tagRed);
        .tagCircle{
            @include theme(background-color, tagRed);
        }
    }

    &--yellow{
        @include theme(background-color, tagYellowBackground);
        @include theme(color, tagYellow);
        .tagCircle{
            @include theme(background-color, tagYellow);
        }
    }

    &--green{
        @include theme(background-color, tagGreenBackground);
        @include theme(color, tagGreen);
        .tagCircle{
            @include theme(background-color, tagGreen);
        }
    }

    &--pink{
        @include theme(background-color, tagPinkBackground);
        @include theme(color, tagPink);
        .tagCircle{
            @include theme(background-color, tagPink);
        }
    }

    &--purple{
        @include theme(background-color, tagPurpleBackground);
        @include theme(color, tagPurple);
        .tagCircle{
            @include theme(background-color, tagPurple);
        }
    }

    &--cyan{
        @include theme(background-color, tagCianBackground);
        @include theme(color, tagCian);
        .tagCircle{
            @include theme(background-color, tagCian);
        }
    }

    &--orange{
        @include theme(background-color, tagOrangeBackground);
        @include theme(color, tagOrange);
        .tagCircle{
            @include theme(background-color, tagOrange);
        }
    }
}
