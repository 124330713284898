@import '../../../styles/colors.scss';

.Notification{
    position: relative;
    border-radius: 4px;
    padding: 25px;

    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    @include theme(background-color, primaryBackground80);
    backdrop-filter: blur(5px);

    box-sizing: border-box;

    // border: 1px solid gray;

    &.full{
        width: 100%;
    }
    &.auto{
        // width: 372px;
        width: auto;
    }

    .Icon{
        position: absolute;
        top: 17px;
        right: 17px;

        @include theme(color, secondaryText);
        cursor: pointer;

        transition: 0.3s;
        &:hover{
            @include theme(color, primaryText);
        }
    }
}

.fadeout-notification {
    animation: fadeOut 0.1s forwards;
    display: none;
}

@keyframes fadeOut {
    from {opacity: 1;}
    to {opacity: 0;}
}

