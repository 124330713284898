.CommandsCenterScreenContainer{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 0px 0px 0px 0px;
    overflow: hidden;

    &--Header{
        padding: 42px 0px 20px 50px;
    }

    &--HeaderLine{
        margin-top: -8px;
    }

    &--Content{
        width: 80%;
        margin-left: 10%;

        margin-top: 30px;

        transition: all 0.2s ease-in-out;

        //width: 100%;
        height: 100%;
        display:flex;
        /*
        overflow-y: auto;
        overflow-x: hidden;
        

        box-sizing: border-box;
        
        margin-top: 30px;
        margin-bottom: 10px;

        padding: 0 16px;
        box-sizing: border-box;
    
        &::-webkit-scrollbar {
            background-color: transparent;
            width: 8px;
        }
        */
        
        &:hover{
            &::-webkit-scrollbar {
                width: 8px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: rgba(0,0,0,.5);
                border-radius: 20px;
            }
        }
    }
}
