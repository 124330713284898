@import '../../../styles/colors.scss';

.PageContainerContainer{
    width: 100%;
    height: 100%;

    transition: all 0.3s ease-in-out;

    @include theme(background, primaryBackground);
}
