
.SignUpFormContainer{
    position: relative;

    height: 100%;
    width: 400px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 15px;

    &--Bottom{
        //position: absolute;
        //bottom: 30px;

        margin-top: 5px;
    }
}
