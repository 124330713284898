@import '../../../styles/colors.scss';

.MenuContainer{
    @include theme(background, primaryBackground);

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    //border-radius: 9px 0px 9px 9px;
    border-radius: 9px 9px 9px 9px;

    padding: 8px;

    position: relative;

    &--Menu{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;

        gap: 8px;

        &--MenuItem{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            height: 25px;
            padding: 4px 6px;
            box-sizing: border-box;
            //margin: 8px 0px;

            border-radius: 4px;
            width: 100%;

            cursor: pointer;

            &:hover{
                @include theme(background, hover);
            }

            &--Icon{
                margin-right: 4px;
                margin-top: 4px;
            }

            &--disabled{
                cursor: not-allowed;
                
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;

                height: 25px;
                padding: 4px 6px;
                box-sizing: border-box;
                //margin: 8px 0px;

                border-radius: 4px;
                width: 100%;

            }
        }
    }

    &--Icon_Fill{
        @include theme(fill, primaryText);
    }

    &--Icon{
        @include theme(stroke, primaryText);
    }
}
