@import '../../../styles/colors.scss';

.pill-sidebar-container{
    display: flex;

    @include theme(background, primaryBackground80);
    backdrop-filter: blur(5px);

    border-radius: 50px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    transition: all 0.2s ease-in-out;

    .pill-sidebar-options{
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 5px;

        width: 100%;
    }

    .pill-sidebar-open-handle{
        position:absolute;
        right: -14px;
        top: calc(50% - 12px);

        padding: 0px;
    }

    .pill-sidebar-username{
        
    }
}

.pill-sidebar-container--open{
    transition: all 0.2s ease-in-out;
    border-radius: 8px;

    width: 190px;
}
