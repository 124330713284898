@import '../../../styles/colors.scss';

.GoogleCalendar{
    position: relative ;
    width: 100%;
    @include theme(background, primaryBackground80);
    backdrop-filter: blur(5px);
    transition: all 0.2s ease-in-out;

    border-radius: 8px;
    height: 170px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    overflow: hidden;

    .GoogleCalendarRow{
        display: grid;
        grid-template-columns: repeat(24, 140px); 
        grid-auto-flow: column;
        grid-gap: 0;
        
        height: 125px; 
        white-space: nowrap;

        overflow-x: auto;
        overflow-y: hidden;
        position: relative;

        &::-webkit-scrollbar {
            background-color: transparent;
            height: 7px;
        }

        &:hover{
            &::-webkit-scrollbar {
                height: 7px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: rgba(0,0,0,.5);
                border-radius: 20px;
            }
        }

        .GoogleCalendarCurrentTimeIndicator{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 2px;
            z-index: 5;
        }

        .GoogleCalendarEvent{
            position: absolute;
            top: 25%;
            bottom: 0;
            left: 0;
            z-index: 1;
        
            height: 70%;
        }

        .GoogleCalendarEventOverlaped{
            position: absolute;
            top: 51%;
            bottom: 0;
            left: 0;
            z-index: 2;
        
            height: 48%;
        }
    }

    .GoogleCalendarInfoBar{
        width: 100%;
        height: 45px;
        @include theme(background, primaryBackground);
        transition: all 0.2s ease-in-out;
        
        border-radius: 0px 0px 4px 4px;

        position: absolute;
        bottom: 0px;
        display: flex;
        justify-content: center;
        //z-index: 1;

        .GoogleCalendarInfoBarContainer{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            padding-left: 16px;
            padding-right: 16px;
        }
    }
}
