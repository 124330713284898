@import '../../../styles/colors.scss';

.KbarKeyInfo{
    padding: 8px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 6px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @include theme(background-color, secondaryBackground);
    @include theme(border, generalStroke, 1px solid);

    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transition: all 0.2s ease-in-out;
}
