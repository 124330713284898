@import '../../../styles/colors.scss';

.SearchBarTemplateContainer{
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    &--KbarInfo{
        position: absolute;
        margin-right: 10px;
    }

    .SearchBarTemplate{
        //width:91.5%;
    
        box-sizing: border-box;
    
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
    
        border: none;
        border-radius: 8px;
        padding: 14px 14px 14px 50px;
    
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    
        @include theme(background, primaryBackground, url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z' stroke='%23757679' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21 20.9999L16.65 16.6499' stroke='%23757679' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") no-repeat 15px center);
        //@include theme(background, primaryBackground);
        //@include theme(border, generalStroke, 1px solid);
        @include theme(color, primaryText);
    
        box-sizing: border-box;
            font-family: "Poppins", sans-serif;
            font-size: 20px;
            width: 100%;
    
            background: none;
            border: none;
    
            @include theme(color, primaryText);
            transition: all 0.2s ease-in-out;
    
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
    
            &::placeholder {
                @include theme(color, tertiaryText);
                transition: all 0.2s ease-in-out;
            }
            &:focus {
                outline: none;
            }
    
        transition: all 0.2s ease-in-out;
    
        &.SearchBarIcon{
            position: absolute;
            top: 50%;
        }
    
    }
}

