@import '../../../styles/colors.scss';

.CalendarHourLineIndicatorContainer{
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    .CalendarHourIndicatorCircle{
        @include theme(background-color, red);
        width: 12px;
        height: 13.6px;
        border-radius: 50%;

        box-sizing: border-box;
    }
    .CalendarHourLineIndicator{
        @include theme(border-left, red, 2px solid);
        height: 100%;
    }
}
