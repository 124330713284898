@import '../../../styles/colors.scss';

.ScreenMessageContainer{
    //width: 100%;
    padding: 20px 35px;

    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    overflow: hidden;

    transition: all 0.2s ease-in-out;


    &--error{
        @include theme(background-color, tabdockDarkBlue);
    }

    &--warning{
        @include theme(background-color, tabdockDarkBlue);

    }

    &--info{
        @include theme(background-color, tabdockDarkBlue);
    }

    &--onScreen{
        @include theme(background-color, strokeBackground);
        border-radius: 10px;
        @include theme(border, generalStroke, 1px solid);
        box-shadow: none;

        transition: all 0.2s ease-in-out;
    }

    &--Icon{
        fill: white;
    }

    &--Icon2{
        stroke: white;
    }

    &--Icon-onScreen{
        @include theme(fill,primaryText);
    }    

    &--Message{
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
    }

    &--CloseIcon{
        position: absolute;
        top: 6px;
        right: 6px;
        cursor: pointer;
    }

    &--Options{

    }
}
