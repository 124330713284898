@import '../../../../styles/colors.scss';

.KBarActionContainer{
    transition: all 0.2s ease-in-out;
    background-color: transparent;

    padding: 10px;
    border-radius: 8px;

    border: 1px solid transparent;
    &--Row{
        transition: all 0.2s ease-in-out;

        display: flex;
        flex-direction: row;

        justify-content: space-between;
        align-items: center; 
    }

    &:hover{
        transition: all 0.2s ease-in-out;
        @include theme(background-color, hover);
    }
}

.KBarActionContainerSelected{
    transition: all 0.2s ease-in-out;
    @include theme(background-color, hover);
    @include theme(border, strokeBackgroundHover, 1px solid);
}

.ShortcutKey{
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 600;

    //width: 12px;
    min-width: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 5px;
    @include theme(color, primaryText);
    @include theme(background-color, secondaryBackground);
    @include theme(border, strokeBackgroundHover, 1px solid);

    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

