@import '../../../styles/colors.scss';

.WorldClockZoneTimeContainer{
    position: relative;
    border: 1.5px dashed transparent;
    padding: 8px;
    transition: all 0.1s ease-in-out;
    cursor: grab;

    border-radius: 3px;

    margin: 0;
    
    .WorldClockZoneDetails{
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    
    .WorldClockZoneTime{
        width: 100%;
        display:flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;

        margin-top: 24px;
    }

    .WorldClockZoneTimeDelete{
        cursor: pointer;
        @include theme(color, secondaryText);
        opacity: 0;

        position: absolute;
        top: 8px;
        right: 8px;
    }

    &:hover{
        @include theme(border, secondaryText, 1.5px dashed);
        .WorldClockZoneTimeDelete{
            opacity: 1;
        }
    }
}

