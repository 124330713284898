@import '../../../styles/colors.scss';

.Line {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;

  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;

  width: 100%;

  &:before {
    content: '';
    @include theme(background, generalStroke);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }

  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;

    padding: 0 0.5em;
    line-height: 1.5em;

    @include theme(color, secondaryText);
    @include theme(background-color, primaryBackground);
  }
}

.Line2 {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;

  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;

  width: 100%;

  &:before {
    content: '';
    @include theme(background, generalStroke);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }

  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;

    padding: 0 0.5em;
    line-height: 1.5em;

    @include theme(color, secondaryText);
    @include theme(background-color, strokeBackground);
  }
}
