@import '../../../styles/colors.scss';

.Link { 
    font-family: "Poppins", sans-serif;
    display: "inline-block";
    margin: 0;
    padding: 0;

    font-size: 16px;

    cursor: pointer;
    text-decoration: none;

    transition: 0.3s;

    &.primary {
        @include theme(color, tabdockBlue);
        font-weight: 700;

        &:hover {
            @include theme(color, tabdockDarkBlue);
            text-decoration: underline;
        }
    }

    &.secondary {
        @include theme(color, secondaryText);
        font-weight: 500;

        &:hover {
            @include theme(color, tabdockDarkBlue);
            text-decoration: underline;
        }
    }
}
