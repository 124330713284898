@import '../../../styles/colors.scss';

.pill-sidebar-nav-button{
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 10px;

    padding: 16px;
    cursor: pointer;

    @include theme(color, secondaryText);
    background-color: transparent;
    border: none;

    transition: all 0.2s ease-in-out;

    &--active{
        @include theme(background-color, actionColorBackground);
        @include theme(color, actionColor);
    }

    &:hover{
        @include theme(background-color, hover);
    }

    &:active{
        @include theme(background-color, actionColorBackgroundClick);
    }

    .pill-sidebar-icon{
        //@include theme(fill, secondaryText);
        //@include theme(stroke, secondaryText);
        
    }

    .pill-sidebar-label{
        font-family: "Poppins", sans-serif;
        display: "inline-block";
        margin: 0;
        padding: 0;
        font-weight: 500;
        font-size: 16px;

        white-space: nowrap; 
        overflow: hidden; 
        text-overflow: ellipsis; 

    }
}

.pill-sidebar-nav-button-expanded{
    transition: all 0.2s ease-in-out;
    border-radius: 6px;

    width: 100%;
    justify-content: flex-start;
}

.pill-sidebar-nav-button-collapsed{
    transition: all 0.2s ease-in-out;
    border-radius: 50px;
}
