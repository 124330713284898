@import '../../../styles/colors.scss';

.ResizableLayoutContent{
    display: flex;
    height: 100%;
    overflow: hidden;

    &--Left{
        //background-color: blue;
    }

    &--Right{
        //background-color: red;
        &--Close{
            position: absolute;
            top: 8px;
            right: 8px;

            width: 24px;
            height: 24px;

            cursor: pointer;
            @include theme(color, secondaryText);

            transition: all 0.3s ease-in-out;

            border-radius: 7px;
            @include theme(border, strokeBackground, 2px solid);
            @include theme(background, primaryBackground);

            z-index: 100;

            &:hover{
                @include theme(color, primaryText);
            }
        }
    }

    &--Separator{
        @include theme(background-color, strokeBackground);

        cursor: col-resize;
        width: 5px;

        transition: all 0.3s ease-in-out;
        &:hover{
            @include theme(background-color, strokeBackgroundHover);
            width: 7px;
            .ResizableLayoutContent--Separator--Handler{
                @include theme(fill, secondaryText);
            }
        }

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &--Handler{
            @include theme(fill, tertiaryText);
            transition: all 0.3s ease-in-out;
        }

        &--OpenButton{
            @include theme(stroke, tertiaryText);
            transition: all 0.3s ease-in-out;
            margin-right: 30px;
            border-radius: 100%;
            
            &:hover{
                @include theme(stroke, secondaryText);
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            }
        }
    }
}
