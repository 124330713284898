@import '../../../styles/colors.scss';

.WorkspacesTopBarContainer{
    //width: 100%;
    //height: 56px;
    height: 46px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding-left: 55px;
    padding-right: 55px;
    padding-top: 1px;

    @include theme(border-top, strokeBackground, 2px solid);
    @include theme(border-bottom, strokeBackground, 2px solid);
    transition: all 0.3s ease-in-out;


    &--Searchbar{
        width: 30%;
        transition: all 0.3s ease-in-out;

        .SearchBarTemplateTopBar{
            width:100%;
            height: 24px;
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
    
            border: none;
            padding: 0px 0px 0px 50px;
    
            @include theme(background, primaryBackground, url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z' stroke='%23757679' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21 20.9999L16.65 16.6499' stroke='%23757679' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") no-repeat 15px center);
            @include theme(color, primaryText);
            @include theme(border-right, strokeBackground, 2px solid);
    
    
            transition: all 0.3s ease-in-out;
    
            &::placeholder {
                @include theme(color, secondaryText); 
            }
    
            &:focus {
                // @include theme(outline, transparent, 2px solid); 
                outline: none;
            }
    
            &.SearchBarIcon{
                position: absolute;
                top: 50%;
            }
        }
    }
}
