@import '../../../styles/colors.scss';

.EditTagListContainer{
    width: 100%;

    &--Header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &--Right{
            
        }
    }
    &--List{

        transition: all 0.3s ease-in-out;
        //height: 100%;

        margin-top: 10px;
        //margin-left: 34px;
        padding: 10px;
        border-radius: 8px;
        @include theme(background-color, secondaryBackground);
    }
}
