@import '../../../styles/colors.scss';

.DropdownMenuContainer{
    position: relative;
    &--Placeholder{
        height: 44px;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;

        width: 100%;
        //@include theme(border, generalStroke, 1px solid);
        
        border-radius: 6px;
        padding: 0 10px;

        float: left;
        -ms-box-sizing: border-box; /* ie8 */
        -khtml-box-sizing: border-box; /* konqueror */
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box; /* Firefox, other Gecko */
        box-sizing: border-box; /* Opera/IE 8+ */
        box-sizing: border-box; /* css3 rec */

        transition: all 0.3s ease-in-out;

        display: flex;
        align-items: center;
        justify-content: space-between;

        cursor: pointer;

        //background-color: white;
        @include theme(background-color, primaryBackground);

        &--Border{
            @include theme(border, inputStroke, 1px solid);
        }

        &--80{
            @include theme(background-color, primaryBackground80);
        }

        &--Icon{
            margin-left: 10px;
            margin-right: 5px;
            @include theme(color, secondaryText);
        }

        &--Input{
            width: 100%;
            height: 100%;
            border: none;
            outline: none;

            background-color: transparent;

            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-weight: 500;
            @include theme(color, secondaryText);

            cursor: pointer;
        
        }

        &:focus {
            @include theme(outline, tabdockBlue, 2px solid); 
        }

        &.full{
            width: 100%;
        }

        &.auto{
            width: auto;
        }

        &.fit-content{
            width: fit-content;
        }
    }

    &--Content{
        //@include theme(border, generalStroke, 1px solid);

        border-radius: 6px;
        //@include theme(background-color, white);
        @include theme(background-color, primaryBackground);

        transition: all 0.3s ease-in-out;

        position: absolute;
        top: 100%;
        margin-top: 5px;

        z-index: 4500;

        width: 100%;

        max-height: 250px;
        overflow-y: auto;

        padding: 0 8px;/* added */
        box-sizing: border-box;/* added */

        &--Border{
            @include theme(border, inputStroke, 1px solid);
        }

        &--80{
            @include theme(background-color, primaryBackground80);
        }

        &::-webkit-scrollbar {
            background-color: transparent;
            width: 8px;
        }
        
        &:hover{
            &::-webkit-scrollbar {
                width: 8px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: rgba(0,0,0,.5);
                border-radius: 20px;
            }
        }
        
        &.expanded{
            margin-top: 10px;
        }

        &.collapsed{
            //height: 0px;
            margin-top: 0px;
            opacity: 0;
            visibility: hidden;
        }
    }

    &.full{
        width: 100%;
    }

    &.auto{
        width: auto;
    }

    &.fit-content{
        width: fit-content;
    }
}
