
.TopGradientBG{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 20%;
    background: linear-gradient(180deg, rgba(0,0,0,0.3) 5%, rgba(0,0,0,0) 95%);
    z-index: 1;
}
