@import '../../../styles/colors.scss';

.WorldClockContainer{
    width: 150px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .WorldClockContentContainer{
        border-radius: 4px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        @include theme(background-color, primaryBackground80);
        backdrop-filter: blur(5px);
        transition: all 0.2s ease-in-out;

        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100%;
        padding: 10px 0px 10px 0px;

        max-height: 420px;

        .WorldClockContentListContainer{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            overflow-y: auto;

            &::-webkit-scrollbar {
                background-color: transparent;
                width: 8px;
            }
            &:hover{
                &::-webkit-scrollbar {
                  width: 8px;
                }
                &::-webkit-scrollbar-thumb {
                  background-color: rgba(0,0,0,.5);
                  border-radius: 20px;
                }
            }
        }

        .WorldClockContentListContainerScroll{
            padding-top: 40px;
        }
    }

    .WorldClockAddSelect{
        margin-top: 5px;
        width: 150px;

        border-radius: 4px;
        border: none;

        //@include theme(background-color, primaryBackground80);
        background-color: transparent;
        @include theme(color, secondaryText);
        backdrop-filter: blur(5px);

        transition: all 0.2s ease-in-out;

        font-family: "Poppins", sans-serif;
        font-size: 14px;

        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

        .react-dropdown-select-clear,
        .react-dropdown-select-dropdown-handle {
          @include theme(color, black);
        }

        .react-dropdown-select-dropdown {
            position: absolute;
            left: 0;
            border: none;
            width: 150px;

            padding: 0;
            display: flex;
            flex-direction: column;
            border-radius: 4px;

            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);


            max-height: 300px;
            overflow: auto;
            z-index: 9;

            @include theme(background, primaryBackground80);
            @include theme(color, secondaryText);

            transition: all 0.2s ease-in-out;
    
            //color: #fff !important;

            &::-webkit-scrollbar {
                background-color: transparent;
                width: 8px;
            }
            &:hover{
                &::-webkit-scrollbar {
                  width: 8px;
                }
                &::-webkit-scrollbar-thumb {
                  background-color: rgba(0,0,0,.5);
                  border-radius: 20px;
                }
            }
        }

        .react-dropdown-select-item {
            @include theme(color, secondaryText);
            transition: all 0.2s ease-in-out;
            border-bottom: 1px solid transparent;

            &:hover {
                @include theme(color, primaryText);
            }
        }

        .react-dropdown-select-item.react-dropdown-select-item-selected,
        .react-dropdown-select-item.react-dropdown-select-item-active {
            @include theme(background-color, actionColor);
            transition: all 0.2s ease-in-out;
            font-weight: bold;
        }

        .react-dropdown-select-item.react-dropdown-select-item-selected {
            @include theme(background-color, actionColorBackground);
            transition: all 0.2s ease-in-out;
        }

        &:focus {
            outline: none;
        }
    }
}
