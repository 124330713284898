@import '../../../styles/colors.scss';

.SideNavBarContainer{
    height: 100%;
    //width: 275px;
    //width: 48px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding-left: 16px;
    padding-right: 16px;

    @include theme(border-right, strokeBackground, 2px solid);
    @include theme(background-color, primaryBackground);

    transition: all 0.3s ease-in-out;

    &--expanded{
        width: 275px;
    }

    &--collapsed{
        width: 48px;
    }

    &--header{
        display: flex;
        flex-direction: column;
        gap: 36px;
        margin-top: 20px;

        position: relative;
        &--statebutton{
            position: absolute;
            top: 10px;
            right: -30px;
        }
    }

    &--bottom{
        margin-bottom: 20px;
    }
}
