@import '../../../styles/colors.scss';

.ProfileDisplayContainer{
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .ProfileDisplayImage{
        width: 48px;
        height: 48px;
        border-radius: 50px;
        object-fit: fill;
    }
}

.ProfileDisplayContainerSimple{
    justify-content: center;
}
