@import '../../../styles/colors.scss';

.CurrentOpenTabsSection{
    position: relative ;
    width: 100%;
    @include theme(background, primaryBackground80);
    transition: all 0.2s ease-in-out;
    backdrop-filter: blur(5px);

    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    display: flex;
    flex-direction: column;
    box-sizing: content-box;


    height: 45%;

    &--InfoBar{
        width: 100%;
        height: 45px;
        @include theme(background, primaryBackground);
        transition: all 0.2s ease-in-out;
        
        border-radius: 4px 4px 0px 0px;

        position: absolute;
        top: 0px;
        display: flex;
        justify-content: center;

        &--Container{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            padding-left: 16px;
            padding-right: 16px;
        }
    }

    &--List{
        margin-top: 40px;
        //height: 430px;
        //height: 250px;
        padding: 16px;
        height: 90%;

        box-sizing: border-box;

        //height: calc(100% - 45px);
    }
}
