@import '../../../styles/colors.scss';

// Circle with number in the middle
.InstallationStepIndex{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    margin-right: 20px;

    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 20px;
    //background-color: red;

}

.pending{
    @include theme(border, secondaryText, 3px solid);
    @include theme(color, secondaryText);
}

.completed{
    @include theme(border, success, 3px solid);
    @include theme(background-color, success);
}

.active{
    @include theme(border, tabdockBlue, 3px solid);
    @include theme(color, tabdockBlue);
}
