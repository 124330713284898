@import '../../../styles/colors.scss';

.CalendarHourContainer{
    height: 100%;
    display: flex;
    flex-direction: row;
    
    gap:5px;
    box-sizing: border-box;

    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 0;
    .CalendarHourLine{
        @include theme(border-left, secondaryText, 1px solid);
        //@include theme(border-left, generalStroke, 1px solid);
        //@include theme(border-left, tertiaryText, 1px solid);
        height: 100%;
    }
}

