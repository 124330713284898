html, body {
  margin: 0;
  padding: 0;
  
  font-family: Poppins, sans-serif;

  overflow: hidden;

  height: 100%;
  width: 100%;
}
