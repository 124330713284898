@import '../../../styles/colors.scss';

.ListContainer{
    height: 100%;
    width: 100%;

    overflow-y: auto;
    overflow-x: hidden;
    
    display:flex;
    flex-direction: column;
    //gap: 5px;

    padding: 0 16px;/* added */
    
    box-sizing: border-box;/* added */

    &::-webkit-scrollbar {
        background-color: transparent;
        width: 8px;
    }
    
    &:hover{
        &::-webkit-scrollbar {
            width: 8px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: rgba(0,0,0,.5);
            border-radius: 20px;
        }
    }
    
    .ListItem{
        width: 100%;
    }
}

.no-padding{
    padding: 0px;
}


