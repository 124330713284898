@import '../../../styles/colors.scss';

.TabGroupContainer{
    width: 100%;
    border-radius: 8px;

    @include theme(background, primaryBackground);

    padding: 11px 14px;
    box-sizing: border-box;

    transition: all 0.2s ease-in-out;

    &--blue{
        @include theme(border, tabGroupBlue, 1.5px solid);
    }

    &--grey{
        @include theme(border, tabGroupGray, 1.5px solid);
    }

    &--red{
        @include theme(border, tabGroupRed, 1.5px solid);
    }

    &--yellow{
        @include theme(border, tabGroupYellow, 1.5px solid);
    }

    &--green{
        @include theme(border, tabGroupGreen, 1.5px solid);
    }

    &--pink{
        @include theme(border, tabGroupPink, 1.5px solid);
    }

    &--purple{
        @include theme(border, tabGroupPurple, 1.5px solid);
    }

    &--cyan{
        @include theme(border, tabGroupCian, 1.5px solid);
    }

    &--orange{
        @include theme(border, tabGroupOrange, 1.5px solid);
    }

    &:hover{
        .TabGroupContainer--Header--Actions{
            opacity: 1;
        }
    }

    &--Header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &--Title{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            gap: 5px;
        }

        &--Actions{
            transition: all 0.2s ease-in-out;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            gap: 5px;
            opacity: 0;
        }
    }

    &--List{
        //width: 100%;

        transition: all 0.3s ease-in-out;

        &--expanded{
            margin-top: 10px;
        }

        &--collapsed{
            height: 0px;
            margin-top: 0px;
            visibility: hidden;
            background-color: transparent;
        }
    }
}
