@import '../../../styles/colors.scss';

.PopupOptionButton{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 8px;

    transition: all 0.2s ease-in-out;
    
    &--Icon{
        width: 44px;
        height: 44px;

        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 36px;
        @include theme(background-color, primaryBackground80);
        @include theme(color, secondaryText);

        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(2px);

        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover{
            @include theme(background-color, hover);
            transition: all 0.2s ease-in-out;
        }
    }
}
