
.SettingsScreenContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 0px 0px 0px;

    height: 100%;

    margin-bottom: 30px;
    overflow-y: auto;
    box-sizing: border-box;/* added */

    &::-webkit-scrollbar {
        background-color: transparent;
        width: 8px;
    }
    
    &:hover{
        &::-webkit-scrollbar {
            width: 8px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: rgba(0,0,0,.5);
            border-radius: 20px;
        }
    }

    &--SidebarContainer{
        position: fixed;

        height: 100%;
        width: 100%;

        top: 0;
        left: 0;

        z-index: 1000;

        &--Left{
            height: 100%;
            width: 100%;

            background: rgba(0, 0, 0, 0.50);
            backdrop-filter: blur(2px);
        }

        &--Subscription{
            height: 100%;
            width: 100%;

            display: flex;
            flex-direction: column;
            gap: 20px;

            &--Title{
                margin-left: 30px;
                margin-top: 50px;
            }
        }
    }

    &--Header{
        padding: 42px 0px 20px 50px;
    }

    &--HeaderLine{
        margin-top: -8px;
    }

    &--Footer{
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: flex-end;
        margin-top: 30px;
        margin-bottom: 30px;

        padding: 0px 50px 0px 50px;
    }

    &--Content{
        //height: 100%;
        margin-left: 48px;
        margin-right: 48px;
        margin-top: 28px;

        margin-bottom: 80px;
        &--Line{
            margin-top: 30px;
            margin-bottom: 30px;
        }
        &--General{
            display: flex;
            flex-direction: column;
            gap: 20px;

            &--Row{
                display: flex;
                flex-direction: row;
                gap: 20px;

                justify-content: space-between;
            }
        }
        &--Details{
            width: 50%;

            display: flex;
            flex-direction: column;
            gap: 20px;
        }
        &--Appearance{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
        &--Integrations{
            margin-top: 0px;
            
            &--List{
                margin-top: 30px;
                margin-bottom: 30px;

                display: flex;
                flex-direction: column;
                gap: 20px;
            }
        }
        &--LogoutActions{
            margin-top: 50px;
            margin-bottom: 30px;

            display: flex;
            flex-direction: row;
            gap: 20px;
            justify-content: flex-end;
        }
        &--Subscription{
            //display: none;
            &--Row{
                width: 100%;

                display: flex;
                flex-direction: column;
                gap: 20px;

                //justify-content: space-between;
                //align-items: center;
            }
        }
        
    }
}
