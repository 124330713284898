.SignInScreenContainerPage{
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;


    .SignInScreenContainer{
        //width: 90%;
        //height: 97%;

        width: 100%;
        height: 100%;

        &--Content{
            height: 100%;
            width: 100%;

            display: flex;
            justify-content: center;
        }
    }

    .SignInScreenContainerMobile{
        //height: calc(100% - 65px);
        height: 100%;
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
    

        overflow: scroll;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}
