
.ScreenBaseContainer{
    height: 90vh;
    width: 100vw;

    overflow: hidden;

    display: flex;
    flex-direction: row;
        
    &--Sidebar{
        
    }

    &--Content{
        height: 100%;
        width: 100%;
    }
}
