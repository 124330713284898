.PrivacyPolicyNav{
    padding: 40px;

    width: auto;

    cursor: pointer;
    width: 10%;
    height: 10%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-left: 30px;

}

.PrivacyPolicyContainerScroll{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 0px 0px 0px;

    height: 100vh;

    margin-bottom: 30px;
    overflow-y: auto;
    box-sizing: border-box;

    &::-webkit-scrollbar {
        background-color: transparent;
        width: 8px;
    }
    
    &:hover{
        &::-webkit-scrollbar {
            width: 8px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: rgba(0,0,0,.5);
            border-radius: 20px;
        }
    }
}

.PrivacyPolicyContainer{
    display: flex;
    flex-direction: column;
    gap:60px;

    //padding: 50px 300px;
    padding: 7% 20%;
    padding-top: 0;
}

ol,ul{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

