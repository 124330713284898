@import '../../../styles/colors.scss';

.WorkspaceTitleContainer{
    position: relative;

    display: flex;
    align-items: flex-start;
    width: auto;

    flex-direction: column;
    flex-wrap: nowrap;
    padding: 4px;

    gap: 10px;


    &--Title{
        display: flex;
        align-items: center;
        width: auto;

        flex-direction: row;
        flex-wrap: nowrap;
        padding: 4px;

        gap: 10px;
    
        .WorkspaceTitleContainerContent{
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;

            gap: 4px;
            border: 1.5px solid transparent;

            transition: all 0.1s ease-in-out;
            padding: 4px;

            .WorkspaceTitleEdit{
                opacity: 0;
            }

            &:hover{
                border: 1.5px dashed white;
                color: white;
                .WorkspaceTitleEdit{
                    opacity: 1;
                }
            }
        }

        .WorkspaceTitleContainerContentThemed{
            &:hover{
                @include theme(border, primaryText, 1.5px dashed);
                @include theme(color, primaryText);
                .WorkspaceTitleEdit{
                    opacity: 1;
                }
            }
        }

        .NoEdit{
            cursor:not-allowed;
        }

        .WorkspaceTitleWarning{
            cursor: pointer;
        }
    }

  

    .WorkspaceTitlePopover{
        position: absolute;
        top: 100%;
        left: 35px;
        z-index: 1000;
    }

    .WorkspaceTitlePopoverSmaller{
        position: absolute;
        top: 100%;
        //left: -100%;
        z-index: 1000;
    }
}

.WorkspaceTitleContainerColumn{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: auto;

    gap: 10px;
}

.WorkspaceTitleContainerRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;

    gap: 10px;
}

.SavingIcon{
    animation: rotate-animation 3s infinite linear;
    color: white;
}

.SavingIconThemed{
    animation: rotate-animation 3s infinite linear;
    @include theme(color, primaryText);
}

.WhiteIcon{
    color: white;
}  

.ThemeIcon{
    @include theme(color, primaryText);
}

@keyframes rotate-animation {
	0% {
		transform: rotate(0deg);
  }
  50% {
		transform: rotate(-180deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}
