@import '../../../styles/colors.scss';

.CommandsListContainer{
    width: 100%;

    &--Header{
        &--Title{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
        
        &--Table{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
        }

        &--Right{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }
    }

    &--List{
       width: 100%;
       margin: 0;
       padding: 0;
       display: flex;
       
       transition: all 0.3s ease-in-out;
       height: 59%;
        
    }
}
