@import '../../../styles/colors.scss';

.TabListContainer{
    width: 100%;
    position:relative;

    &--List{
        margin-left: 34px;
        padding: 10px;
        border-radius: 8px;
        @include theme(background-color, secondaryBackground);
        transition: all 0.3s ease-in-out;

        &--expanded{
            margin-top: 10px;

        }

        &--collapsed{
            height: 0px;
            margin-top: 0px;
            visibility: hidden;
            background-color: transparent;
        }
    }
}

.tablistcalendaricon{
    @include theme(stroke, secondaryText);
}

.TabListContainerMenu{
    position:absolute;
    z-index: 30;
    right: 10px;
}

.StartIcon{
    @include theme(stroke, secondaryText);
}

.DropZoneActive{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(93, 123, 255, 0.17);
    border: 3px dashed #4766f3;
    border-radius: 9px;
    z-index: 10;
    //pointer-events: none;
}

.DropZoneActive p {
    color: #4766f3;
    font-size: 1.2em;
    font-weight: bold;
}

.DropZoneActiveGreen{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(131, 226, 129, 0.2);
    border: 3px dashed #92D591;
    border-radius: 9px;
    z-index: 10;
    //pointer-events: none;
}

.DropZoneActiveGreen p {
    color: #92D591;
    font-size: 1.2em;
    font-weight: bold;
}
