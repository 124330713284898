@import '../../../styles/colors.scss';

.ColorOptionContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    &--Circle{
        width: 8px;
        height: 8px;
        border-radius: 50%;

        &.blue{
            @include theme(background-color, tagBlue);
        }
        &.grey{
            @include theme(background-color, tagGray);
        }
    
        &.red{
            @include theme(background-color, tagRed);
        }
    
        &.yellow{
            @include theme(background-color, tagYellow);
        }
    
        &.green{
            @include theme(background-color, tagGreen);
        }
    
        &.pink{
            @include theme(background-color, tagPink);
        }
    
        &.purple{
            @include theme(background-color, tagPurple);
        }
    
        &.cyan{
            @include theme(background-color, tagCian);
        }
    
        &.orange{
            @include theme(background-color, tagOrange);
        }
    }
}
