@import '../../../styles/colors.scss';

.TabRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    //width: 100%;

    padding: 8px;

    transition: all 0.2s ease-in-out;
    border-radius: 8px;

    background-color: transparent;
    cursor: pointer;

    .TabRowActions{
        opacity: 0;
    }

    &:hover{
        @include theme(background-color, hover);
        .TabRowActions{
            opacity: 1;
        }
    }

    &--TabInfo{
        display: flex;
        flex-direction: row;
        
        width: 40%;
    }
    &--TagList{
        display: flex;
        flex-direction: row;
        
        width: 40%;
    }
}
