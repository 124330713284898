@import '../../../styles/colors.scss';


.KbarContainer{
    width: 50%;

    border-radius: 8px;
    @include theme(background, primaryBackground);
    @include theme(border, generalStroke, 1px solid);

    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transition: all 0.2s ease-in-out;

    display: flex;
    flex-direction: column;
    gap: 0px;

    padding: 15px;
    padding-top: 18px;

    margin: 10px;

    margin-top: 20%;

    margin-left: 25%;
    
    &--Input{
        box-sizing: border-box;
        font-family: "Poppins", sans-serif;
        font-size: 20px;
        width: 100%;

        background: none;
        border: none;

        @include theme(color, primaryText);
        transition: all 0.2s ease-in-out;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &::placeholder {
            @include theme(color, tertiaryText);
            transition: all 0.2s ease-in-out;
        }
        &:focus {
            outline: none;
        }
    }

    &--Actions{
        display: flex;
        flex-direction: column;
        gap: 3px;

        max-height: 500px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            background-color: transparent;
            width: 8px;
        }
        
        &:hover{
            &::-webkit-scrollbar {
                width: 8px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: rgba(0,0,0,.5);
                border-radius: 20px;
            }
        }
    }

    &--Tooltips{
        display: flex;
        flex-direction: row;
        gap: 3px;

        align-self: flex-end;
    }

    &--Message{
        display: flex;
        flex-direction: row;
        gap: 3px;

        justify-content: space-between;
    }
}

.KBarActionIcon{
    @include theme(stroke, secondaryText);
}

.KBarActionIconFill{
    @include theme(fill, secondaryText);
}

.KBarActionIconSuccess{
    @include theme(stroke, green);
}

.KBarActionIconFillSuccess{
    @include theme(fill, green);
}

.KBarActionIconError{
    @include theme(stroke, red);
}

.KBarActionIconFillError{
    @include theme(fill, red);
}

