@import '../../../styles/colors.scss';

.IconSpecialButton{
    @include theme(background-color, primaryBackground80);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 80%;

    width: 48px;
    height: 48px;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    transition: all 0.2s ease-in-out;

    position: relative;

    &:hover{
        @include theme(background-color, primaryBackground);
        transition: all 0.2s ease-in-out;
    }

    .IconSpecialButtonIcon{
        @include theme(fill, secondaryText);
        @include theme(stroke, secondaryText);
    }
}
