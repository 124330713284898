.ModalContainer{
    position: fixed;

    height: 100%;
    width: 100%;

    top: 0;
    left: 0;

    background: rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(2px);

    z-index: 2000;



    &--Content{
        position: absolute;
    }
}

.ModalContainerCenter{
    display: flex;
    justify-content: center;
    align-items: center;
}
