@import '../../../styles/colors.scss';

.popup-top-bar{
    width: 100%;
    height: 50px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    //padding-left: 18px;
    //padding-right: 18px;

    @include theme(background-color, primaryBackground);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    
    &__right{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        gap: 10px;

        margin-right: 18px;
    }
}
