@import '../../../styles/colors.scss';

.sidebar-nav-button{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 18px;

    cursor: pointer;
    gap: 8px;

    border: none;
    border-radius: 4px;

    width: 100%;
    height: 48px;
    background-color: transparent;

    transition: all 0.2s ease-in-out;
    
    &--active{
        @include theme(background-color, actionColorBackground);
    }

    &:hover{
        @include theme(background-color, hover);
    }

    &:active{
        @include theme(background-color, actionColorBackgroundClick);
    }

    position: relative;

    .icon{
        position: absolute;
        z-index: 30;
        width: 24px;
        height: 24px;

        left: 13px;
        @include theme(stroke, primaryText);
        @include theme(color, primaryText);
        &--active{
            @include theme(stroke, actionColor);
            @include theme(color, actionColor);
        }
    }

    .label{
        font-family: "Poppins", sans-serif;
        display: "inline-block";
        margin: 0;
        padding: 0;
        @include theme(color, primaryText);
        font-weight: 500;

        white-space: nowrap; /* Prevent text from wrapping */
        overflow: hidden; /* Hide overflow text */
        text-overflow: ellipsis; /* Display ellipsis for truncated text */

        &--active{
            @include theme(color, actionColor);
        }
    }
}

.sidebar-nav-button-expanded{
    padding-left: 50px;
}

.sidebar-nav-button-collapsed{
    padding-left: 0px;
}
