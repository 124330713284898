@import '../../../styles/colors.scss';

.ExtensionPopup{
    width: 340px;
    height: 400px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include theme(background-color, primaryBackground);

    &--topbar{
        width: 100%;
        margin-bottom: 10px;
    }
    
    &--content{
        width: 100%;
        height: calc(100% - 50px);

        position: relative;
        //padding: 18px;

        display: flex;
        flex-direction: column;
        

        @include theme(background-color, primaryBackground);


        &--topRight{
            position: absolute;
            top: 8px;
            right: 18px;
        }

        &--bottomRight{
            position: absolute;
            bottom: 18px;
            right: 18px;

            cursor: pointer;
        }

        &--bottomLeft{
            position: absolute;
            bottom: 5px;
            left: 5px;

            z-index: 10;
        }

        &--content{
            display: flex;
            flex-direction: column;
            
            gap: 10px;

            align-items: center;

            margin-top: 18%;

            &--options{
                display: flex;
                flex-direction: row;
                gap: 40px;

                align-items: center;
                justify-content: space-between;

                position: relative;
                z-index: 10;
            }
        }
    }
}
