.TabInfoImage{
    width: 32px;
    height: 32px;

    border-radius: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
    img{
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering:crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
    
        width: 28px;
        height: 28px;

        border-radius: 30px;
    }

}
