@import '../../../styles/colors.scss';

.DropDownMenuOption{
   // width: 100%;
    
    padding: 8px;

    transition: all 0.2s ease-in-out;
    border-radius: 6px;

    background-color: transparent;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &:hover{
        @include theme(background-color, hover);
    }

    &.DropDownMenuOptionActive{
        @include theme(background-color, actionColorBackground)
    }
}
