@import '../../../styles/colors.scss';

.TooltipContainer{
    border-radius: 4px;
    padding: 6px;

    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    @include theme(background-color, primaryBackground);
    @include theme(border, strokeBackgroundHover, 1.5px solid);

    word-wrap: break-word;
    
    font-family: "Poppins", sans-serif;
    display:flex;
    flex-direction: column;
    gap: 8px;

    align-items: center;

    position: absolute;
    z-index: 99;

    &--Message{
        font-size: 12px;
        font-weight: 400;
        @include theme(color, secondaryText);
        padding: 0;
        margin: 0;
    }
}
